import axios from '@/axios';
import i18n from "@/i18n";

const state = {
    isLoading: false,
    languages: [],
    currentLanguage: localStorage.getItem('language') || "10",
    currencies: [],
    currency: localStorage.getItem("currency"),
    isAuth: false,
}

const getters = {
    currentLanguage: state => state.currentLanguage,
    currentLanguageName: ({currentLanguage, languages}) => {
        if (currentLanguage) {
            const lang = languages.find((lang) => lang.id == currentLanguage);

            if (lang) {
                return lang.title;
            }
        }

        return '';
    },
    currentLanguageCode: ({currentLanguage, languages}) => {
        if (currentLanguage) {
            const lang = languages.find((lang) => lang.id == currentLanguage);
            if (lang) {
                return lang.code;
            }
        }

        return '';
    },
    allLanguages: state => state.languages,
    currency: state => state.currency,
    currencySymbol: ({currencies, currency}) => {
        if (currency) {
            const curr = currencies.find(({id}) => currency === id);

            if (curr) {
                return curr.symbol;
            }
        }

        return '';
    },
    isAuthenticated: (state) => state.isAuth,
}

const actions = {
    fetchLanguages: async ({commit, dispatch, state: {currentLanguage}}) => {
        try {
            commit('setLoading', true);
            const {data: {status, payload}} = await axios.get(process.env.VUE_APP_API + 'languages');
            if (status === 'OK') {
                commit('setLanguages', payload);

                if (!currentLanguage && payload.length) {
                    await dispatch('changeLocale', payload[0].id)
                    // commit('setCurrentLanguage', payload[0].id);
                } else {
                    await dispatch('changeLocale', localStorage.getItem('language'))
                    // commit('setCurrentLanguage', localStorage.getItem('language'));
                }
            }
        } catch (e) {
            console.log(e);
        } finally {
            commit('setLoading', false);
        }
    },
    changeLocale: async ({commit, getters}, localeId) => {
        if (localeId) {
            commit('setCurrentLanguage', localeId);

            try {
                const {data} = await axios.get('/localizations', {
                    params: {
                        lang_id: localeId
                    }
                });

                if (data) {
                    let translations = {};

                    if (Array.isArray(data) && data.length) {
                        data.forEach((translation) => translations[Object.keys(translation)[0]] = Object.values(translation)[0])
                    } else if (typeof data === 'object') {
                        translations = data;
                    }

                    const {currentLanguageCode} = getters;

                    i18n.setLocaleMessage(currentLanguageCode, translations)
                    i18n.locale = currentLanguageCode;
                }
            } catch (e) {
                console.log(e);
            }
        }
    },
    fetchCurrencies: async ({ commit }) => {
        try {
          commit("setLoading", true);
          const {
            data: { status, payload },
          } = await axios.get(process.env.VUE_APP_API + "currencies");
          if (status === "OK") {
            const currencies = payload.map(({code: text, symbol}) => ({text, id: text, symbol}));
            commit("setCurrencies", currencies);

            const storedCurrency = localStorage.getItem("currency");
            if (
                storedCurrency &&
                currencies.some(({ code }) => code === storedCurrency)
            ) {
              commit("setCurrency", storedCurrency);
            } else {
              commit("setCurrency", currencies[0]?.text);
            }
          }
        } catch (e) {
          console.log(e);
        } finally {
          commit("setLoading", false);
        }
      },
    checkAuth: async ({ commit }) => {
        try {
            const { data } = await axios.get(process.env.VUE_APP_API + "is-auth");
            commit('setAuth', data.authenticated);
            return data.authenticated;
        } catch (error) {
            commit('setAuth', false);
            return false;
        }
    },
}

const mutations = {
    setLoading: (state, loading) => (state.isLoading = loading),
    setLanguages: (state, languages) => (state.languages = languages),
    setCurrencies: (state, currencies) => (state.currencies = currencies),
    setCurrency: (state, currency) => {
        localStorage.setItem('currency', currency);
        state.currency = currency;
    },
    setCurrentLanguage: (state, language) => {
        state.currentLanguage = language;
        localStorage.setItem('language', language);
    },
    setAuth: (state, value) => {
        state.isAuth = value;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}